.footer-container {
  background-color: #242424;
  padding: 0rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-image {
  width: 200px;
  padding-bottom: 12px;
}

.footer-links {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping to a new row if necessary */
}

.footer-link-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping in case of narrow screens */
  justify-content: space-between; /* Ensure equal spacing */
  width: 100%;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: center;
  width: 160px;
  box-sizing: border-box;
  min-height: 150px; /* Set a minimum height to ensure even alignment */
}

.footer-link-items h2 {
  margin-bottom: 16px;
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #b6b5b5;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}


.social-media-wrap {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto 0 auto; /* Center the wrapper itself */
  gap: 50px; /* Adjust this value to control the space between elements */
  transform: translate(0px);
}

.contact-email {
  text-decoration: none;
  color: #fff;
}
.contact-email:hover {
  text-decoration: underline;
}

.contact-address {
  color: #fff;
}

.footer-logo {
  display: flex; /* Enable Flexbox */
  justify-content: space-between; /* Space the items apart */
  align-items: center; /* Align items vertically in the center */
  text-align: center;
  width: 100%; /* Ensure the div takes full width */
  padding: 10px 0; /* Optional: Add some padding */
  gap: 20px; /* Add a gap between the items */
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 781px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-items {
    margin-left: 51px;
    margin-right: 51px;
  }
  .footer-link-wrapper {
    justify-content: space-evenly;
  }

  /* .social-media-wrap {
    flex-direction: column;
  } */
}

@media screen and (max-width: 500px) {
  .footer-link-items {
    margin-left: 10px;
    margin-right: 10px;
  }

  .logo-image {
    width: 140px;
  }
}