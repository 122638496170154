/* Container for the columns */
.row {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of columns if needed */
    justify-content: space-between; /* Ensures even spacing between columns */
    padding-top: 15px;

    margin-bottom: 0px;
}

/* Three columns side by side for large screens */
.column {
    flex: 1 1 33%; /* Flex-grow, flex-shrink, and flex-basis: 30% ensures 3 per row */
    margin-bottom: 16px;
    padding: 0px;
    box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

/* Two columns side by side for medium screens */
@media screen and (max-width: 900px) {
    .column {
        flex: 1 1 45%; /* Adjust the flex-basis to 45% for two columns */
        max-width: 45%; /* Ensure the column spans 45% of the row */
    }
}

/* Display in one column on small screens */
@media screen and (max-width: 600px) {
    .column {
        flex: 1 1 100%; /* Make each column take full width on small screens */
        max-width: 100%; /* Ensure the column spans the full width */
        display: block;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.container-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    height: 250px;  /* Ensure the image has a fixed height */
    object-fit: cover; /* Crop the image to fit the dimensions */
}

.container h3 {
    text-align: center;
}

.italic-text {
    /* font-family: Verdana; */
    font-style: italic;
    font-size: 14px; /* Adjust the font size as needed */
    color: #000000; /* Adjust the color as needed */
    text-align: center;
    margin: 0 auto;
    max-width: 250px;
}