.page-container {
    display: block;
    width: 90%;
    /* margin: 0 auto; */
    /* padding: 20px; */
    box-sizing: border-box;
    background-color: #fff;

    max-width: 1000px;
    padding-bottom: 50px;
}

.section-title {
    text-align: center;
    margin-top: 20px;
}

.database-item {
    display: flex; /* Flexbox to align image and header horizontally */
    align-items: flex-start; /* Align the items at the start (top of the row) */
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: left;
    margin-right: auto;
    margin-left: auto;
}

.database-img {
    border-radius: 10px;
    width: 200px;
    height: 200px;
    margin-right: 20px; /* Space between image and description */
}

.database-text {
    display: block;
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    padding-top: 10px;
    line-height: 1.7;
    font-size: 10px;
}

@media screen and (max-width: 600px) {
    .database-img {
        width: 50px;
        height: 50px;
        float: left; /* Keep the image floating left on mobile */
        margin-right: 10px; /* Add smaller spacing on mobile */
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .database-text {
        overflow: hidden; /* Ensure text wraps properly around the image */
        /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
        padding-top: 10px;
        line-height: 1.7;
    }
}