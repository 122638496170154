.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    max-width: 1300px;

    padding-bottom: 50px;
}

.about {
    display: flex;
}
.about img {
    width: 200px;
    height: 200px;
    margin-right: 20px;
}

.section-title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2.0rem;
}

.page-container p {
    font-size: 1.1rem;
    color: #000;
}

.scheme-img{
    transform: scale(0.97);
    max-width: 100%; /* Ensures the image scales down to fit the container */
    height: auto;    /* Maintains the aspect ratio */
    display: block;  /* Removes any extra space around the image */
    transition: transform 0.3s ease-in-out;
}

.scheme-img.scaled {
    transform: scale(1.2);
}

.funding-section {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 20px;
    margin-left: 30px;
}

.nsf-logo {
    width: 100px; /* Adjust the size as needed */
    margin-right: 20px;
}

.funding-links {
    display: flex;
    flex-direction: column;
}

.funding-links a {
    color: #0066cc; /* Link color */
    text-decoration: none;
    margin-top: 10px;
    font-size: 16px;
}

.funding-links a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
    .about {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .about img {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        padding: 20px;
    }
    
}

