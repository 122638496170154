.help {
    padding: 50px;
    text-align: center;
}

/* Arbitrary positioned background image */
.background-image {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
    z-index: -1; /* Behind other content */
    transition: transform 0.1s linear; /* Smooth transition for scrolling rotation */
}

/* Position specific images */
.top-left {
    top: 400px;
    left: 300px;
    transform: rotate(45deg);
    background-image: url('/public/images/Blue_Chol.png');
}

.bottom-right {
    bottom: 300px;
    right: 300px;
    transform: rotate(90deg);
    background-image: url('/public/images/Blue_Chol.png');
}

.top-right {
    top: 200px;
    right: 300px;
    background-image: url('/public/images/Blue_Chol.png');
}

.long-content {
    margin-top: 200px;
    height: 100vh; /* More content to scroll through */
}