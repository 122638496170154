.container-info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    max-width: 1125px;

}

.container-info p {
    font-size: 1.1rem;
    color: #000;
}

@media screen and (max-width: 600px) {
    .container-info {
        display: block;
        width: 90%;
        padding: 20px 10px 0px 10px;
    }
}
